import React, { useState } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, Input } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';


const InPerson = ({ model, setRouteTransition, onChangeHandler }) => {

  const branchString = `[
{
        "ID": 20,
        "BranchGroup": 6,
        "BranchName": "Carroll, IA",
        "Address": "503 Hwy 30 W",
        "Address2": "",
        "City": "Carroll",
        "State": "IA",
        "Zip": 51401,
        "LocalPhone": "(712) 792-1735",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(712) 792-1874",
        "LobbyHours": "M-Fr: 8:30 a.m.-5 p.m. Sat: 8:30 a.m.-12 p.m.",
        "DriveupHours": "M-Fr: 8:00 a.m.-5:30 p.m. Sat: 8:30 a.m.-12 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 20,
        "Latitude": 42.064115,
        "Longitude": -94.861233,
        "Active": 1
      },  
      {
        "ID": 23,
        "BranchGroup": 2, 
        "BranchName": "Cedar Rapids, IA (Edgewood Rd.)",
        "Address": "4615 CrossPointe Blvd. NE",
        "Address2": "",
        "City": "Cedar Rapids",
        "State": "IA",
        "Zip": 52411,
        "LocalPhone": "(319) 366-8231",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(319) 365-7445", 
        "LobbyHours": "M-Th: 8:30 a.m.-5:00 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "DriveupHours": "M-Fr: 8 a.m.-6:00 p.m. Sat: 8:00 a.m.-1:00 p.m.",
        "SharedBranching": 0, 
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 23,
        "Latitude": 0,  
        "Longitude": 0, 
        "Active": 1
      },
        {
          "ID": 13,
          "BranchGroup": 2,
          "BranchName": "Cedar Rapids, IA (35th St.)",
          "Address": "110 35th St Dr SE",
          "Address2": "",
          "City": "Cedar Rapids",
          "State": "IA",
          "Zip": 52403,
          "LocalPhone": "(319) 366-8231",
          "TollFreePhone": "(800) 373-7600",
          "BranchExt": "",
          "Fax": "(319) 247-9630",
          "LobbyHours": "M-Th: 8:30 a.m.-5:00 p.m. Fr: 8:30 a.m-6 p.m. Sat: 8:30 a.m.-12:30 p.m",
          "DriveupHours": "M-Th: 8:30 a.m.-5:00 p.m. Fr: 8:30 a.m-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
          "SharedBranching": 1,
          "BranchImage": "../images/branches/cr1.png",
          "BranchID": 17,
          "Latitude": 42.013743,
          "Longitude": -91.634661,
          "Active": 1
        },
 {
        "ID": 15,
        "BranchGroup": 2,
        "BranchName": "Cedar Rapids, IA (Williams Blvd.)",
        "Address": "3131 Williams Blvd SW",
        "Address2": "",
        "City": "Cedar Rapids",
        "State": "IA",
        "Zip": 52404,
        "LocalPhone": "(319) 366-8231",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(319) 364-0507",
        "LobbyHours": "M-Th: 8:30 a.m.-5 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "DriveupHours": "M-Th: 8:30 a.m.-5 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/crw.png",
        "BranchID": 15,
        "Latitude": 41.959942,
        "Longitude": -91.712232,
        "Active": 1
      },
 {
             "ID": 70,
          "BranchGroup": 2,
          "BranchName": "Cedar Rapids, IA (Live Video Teller Outlet @ NewBo City Market)",
          "Address": "1100 3rd St. SE",
          "Address2": "",
          "City": "Cedar Rapids",
          "State": "IA",
          "Zip": 52401,
          "LocalPhone": "N/A",
          "TollFreePhone": "(800) 373-7600",
          "BranchExt": "",
          "Fax": "N/A",
          "LobbyHours": "M-Wed: 8:00 a.m.—2:00 p.m. Thu-Fri 8:00 a.m.—6:00 p.m. Sat: 8:00 a.m.—1:00 p.m.",
          "DriveupHours": "No drive-up available",
          "SharedBranching": 0,
          "BranchImage": "../images/branches/dupaco_default.jpg",
          "BranchID": 99,
          "Latitude": 42.498882,
          "Longitude": -90.657222,
          "Active": 1
        },
        {
          "ID": 24,
          "BranchGroup": 2, 
          "BranchName": "Coralville, IA (Coral Ridge)",
          "Address": "2530 Corridor Way",
          "Address2": "",
          "City": "Coralville",
          "State": "IA",
          "Zip": 52241,
          "LocalPhone": "(800) 373-7600",
          "TollFreePhone": "(800) 373-7600",
          "BranchExt": "",
          "Fax": "", 
          "LobbyHours": "M-Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-1:00 p.m.",
          "DriveupHours": "M-Fr: 8:00 a.m.-6:00 p.m. Sat: 8:00 a.m.-1:00 p.m.",
          "SharedBranching": 0, 
          "BranchImage": "../images/branches/dupaco_default.jpg",
          "BranchID": 24,
          "Latitude": 41.696670,  
          "Longitude": -91.607210, 
          "Active": 1
        },

 {
        "ID": 5,
        "BranchGroup": 3,
        "BranchName": "Dubuque, IA (Inside Locust St. Hy-Vee)",
        "Address": "400 S Locust St",
        "Address2": "(Inside Hy-Vee)",
        "City": "Dubuque",
        "State": "IA",
        "Zip": 52003,
        "LocalPhone": "(563) 557-7600",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 690-1110",
        "LobbyHours": "M-Fr: 9 a.m.-7 p.m. Sat: 9 a.m.-4 p.m. Sun: 11 a.m.-4 p.m.",
        "DriveupHours": "No drive-up available",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/hyvee.png",
        "BranchID": 5,
        "Latitude": 42.486759,
        "Longitude": -90.663007,
        "Active": 1
      },
 {
        "ID": 6,
        "BranchGroup": 3,
        "BranchName": "Dubuque, IA (Flint Hill Dr.)",
        "Address": "2245 Flint Hill Dr",
        "Address2": "",
        "City": "Dubuque",
        "State": "IA",
        "Zip": 52003,
        "LocalPhone": "(563) 557-7600",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 584-2003",
        "LobbyHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: Closed",
        "DriveupHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/keywest.png",
        "BranchID": 6,
        "Latitude": 42.459508,
        "Longitude": -90.675154,
        "Active": 1
      },
  {
        "ID": 1,
        "BranchGroup": 3,
        "BranchName": "Dubuque, IA (Hillcrest Rd.)",
        "Address": "3299 Hillcrest Rd",
        "Address2": "",
        "City": "Dubuque",
        "State": "IA",
        "Zip": 52001,
        "LocalPhone": "(563) 557-7600",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 584-2241",
        "LobbyHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "DriveupHours": "M-Th: 7:30 a.m.-5:30 p.m. Fr: 7:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/hillcrest.png",
        "BranchID": 1,
        "Latitude": 42.506469,
        "Longitude": -90.71952,
        "Active": 1
      }, 
  {
        "ID": 16,
        "BranchGroup": 3,
        "BranchName": "Dubuque, IA (Pennsylvania Ave.)",
        "Address": "3999 Pennsylvania Ave",
        "Address2": "",
        "City": "Dubuque",
        "State": "IA",
        "Zip": 52002,
        "LocalPhone": "(563) 557-7600",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 690-0069",
        "LobbyHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m-6 p.m. Sat: Closed",
        "DriveupHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/penn.png",
        "BranchID": 16,
        "Latitude": 42.50191,
        "Longitude": -90.739733,
        "Active": 1
      },
{
        "ID": 9,
        "BranchGroup": 3,
        "BranchName": "Dubuque, IA (Saratoga Rd.)",
        "Address": "5865 Saratoga Rd",
        "Address2": "",
        "City": "Asbury",
        "State": "IA",
        "Zip": 52002,
        "LocalPhone": "(563) 557-7600",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 557-6223",
        "LobbyHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: Closed",
        "DriveupHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/asbury.png",
        "BranchID": 9,
        "Latitude": 42.509365,
        "Longitude": -90.757738,
        "Active": 1
      },
      {
        "ID": 2,
        "BranchGroup": 3,
        "BranchName": "Dubuque, IA (Sycamore St.)",
        "Address": "1465 Sycamore St",
        "Address2": "",
        "City": "Dubuque",
        "State": "IA",
        "Zip": 52001,
        "LocalPhone": "(563) 557-7600",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 557-7763",
        "LobbyHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: Closed",
        "DriveupHours": "M-Th: 7:30 a.m.-5:30 p.m. Fr: 7:30 a.m.-6 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/sycamore.png",
        "BranchID": 2,
        "Latitude": 42.508943,
        "Longitude": -90.660819,
        "Active": 1
      },
      {
        "ID": 4,
        "BranchGroup": 4,
        "BranchName": "Dyersville, IA",
        "Address": "807 9th St SE",
        "Address2": "",
        "City": "Dyersville",
        "State": "IA",
        "Zip": 52040,
        "LocalPhone": "(563) 875-2795",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 875-2796",
        "LobbyHours": "M-Th: 8:30 a.m.-5 p.m. Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "DriveupHours": "M-Th: 8:30 a.m.-5 p.m. Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/dyersville.png",
        "BranchID": 4,
        "Latitude": 42.478004,
        "Longitude": -91.113549,
        "Active": 1
      },
      {
        "ID": 7,
        "BranchGroup": 5,
        "BranchName": "Galena, IL",
        "Address": "11375 Oldenburg Ln",
        "Address2": "",
        "City": "Galena",
        "State": "IL",
        "Zip": 61036,
        "LocalPhone": "(815) 777-1800",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(815) 777-1919",
        "LobbyHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12 p.m.",
        "DriveupHours": "M-Th: 8:30 a.m.-5:30 p.m. Fr: 8:30 a.m.-6 p.m. Sat: 8:30 a.m.-12 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/galena.png",
        "BranchID": 7,
        "Latitude": 42.436791,
        "Longitude": -90.450773,
        "Active": 1
      },{
        "ID": 12,
        "BranchGroup": 1,
        "BranchName": "Grimes, IA",
        "Address": "1701 E. 1st St.",
        "Address2": "",
        "City": "Grimes",
        "State": "IA",
        "Zip": 50111,
        "LocalPhone": "N/A",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "N/A",
        "LobbyHours": "M-Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "DriveupHours": "M-FR: 8:00 a.m.-6 p.m. Sat: 8:00 a.m.-1 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 12,
        "Latitude": 41.688530,
        "Longitude": -93.778120,
        "Active": 1
      },
      {
        "ID": 26,
        "BranchGroup": 5,
        "BranchName": "Madison, WI (Washington Ave.)",
        "Address": "3762 E. Washington Ave.",
        "Address2": "",
        "City": "Madison",
        "State": "WI",
        "Zip": 53704,
        "LocalPhone": "(608) 282-6000",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "N/A",
        "LobbyHours": "M-Fr: 9:00 a.m.-5:00 p.m.",
        "DriveupHours": "M-Fr: 9:00 a.m.-5:00 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 12,
        "Latitude": 43.119140,
        "Longitude": -89.322050,
        "Active": 1
      },
      {
        "ID": 25,
        "BranchGroup": 5,
        "BranchName": "Madison, WI (Mineral Point Rd.)",
        "Address": "7701 Mineral Point Rd.",
        "Address2": "",
        "City": "Madison",
        "State": "WI",
        "Zip": 53717,
        "LocalPhone": "(608) 282-6000",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "N/A",
        "LobbyHours": "M-Fr: 9:00 a.m.-5:00 p.m.",
        "DriveupHours": "M-Fr: 9:00 a.m.-5:00 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 12,
        "Latitude": 43.060770,
        "Longitude": -89.516210,
        "Active": 1
      }
      ,
      {
        "ID": 14,
        "BranchGroup": 4,
        "BranchName": "Manchester, IA",
        "Address": "1200 W Main St",
        "Address2": "",
        "City": "Manchester",
        "State": "IA",
        "Zip": 52057,
        "LocalPhone": "(563) 927-6187",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 927-6307",
        "LobbyHours": "M-Th: 8:30 a.m.-5 p.m. Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12 p.m.",
        "DriveupHours": "M-Th: 8:30 a.m.-5 p.m. Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/manchester.png",
        "BranchID": 10,
        "Latitude": 42.483192,
        "Longitude": -91.474226,
        "Active": 1
      },     
      {
        "ID": 19,
        "BranchGroup": 2,
        "BranchName": "Marion, IA",
        "Address": "5970 Carlson Way, Marion, IA",
        "Address2": "",
        "City": "Marion",
        "State": "IA",
        "Zip": 52302,
        "LocalPhone": "(319) 366-8231",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(319) 200-4360",
        "LobbyHours": "M-Th: 8:30 a.m.-5:00 p.m. Fr: 8:30 a.m.-6:00 p.m.Sat: 8:30 a.m.-12:30 p.m.",
        "DriveupHours": "M-Fr: 8:00 a.m.-6:00 p.m. Sat: 8:00 a.m.-1:00 p.m. 24-hour ATM access",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 19,
        "Latitude": 42.033176,
        "Longitude": -91.596506,
        "Active": 1
      },
      {
        "ID": 3,
        "BranchGroup": 4,
        "BranchName": "Peosta, IA",
        "Address": "185 Peosta St",
        "Address2": "",
        "City": "Peosta",
        "State": "IA",
        "Zip": 52068,
        "LocalPhone": "(563) 582-2805",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(563) 582-2714",
        "LobbyHours": "M-Fr: 9:30 a.m.-5:30 p.m. Sat: 9:30 a.m.-12:30 p.m. ",
        "DriveupHours": "M-Fr: 8 a.m.-6 p.m. Sat: 8 a.m.-1 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/peosta.jpg",
        "BranchID": 3,
        "Latitude": 42.44289,
        "Longitude": -90.851387,
        "Active": 1
      },
      {
        "ID": 8,
        "BranchGroup": 5,
        "BranchName": "Platteville, WI",
        "Address": "1100 E. Business Hwy. 151",
        "Address2": "",
        "City": "Platteville",
        "State": "WI",
        "Zip": 53818,
        "LocalPhone": "(608) 348-4499",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(608) 348-9505",
        "LobbyHours": "M-Th: 8:30 a.m.-5 p.m. Fr: 8:30 a.m.-5:30 p.m. Sat: 9 a.m.-12 p.m.",
        "DriveupHours": "M-Th: 8 a.m.-5:30 p.m. Fr: 8 a.m.-6 p.m. Sat: 9 a.m.-12 p.m.",
        "SharedBranching": 1,
        "BranchImage": "../images/branches/platteville.png",
        "BranchID": 8,
        "Latitude": 42.731204,
        "Longitude": -90.461747,
        "Active": 1
      },
      {
        "ID": 21,
        "BranchGroup": 1,
        "BranchName": "Waterloo, IA (Schukei Rd.)",
        "Address": "1946 Schukei Rd",
        "Address2": "",
        "City": "Waterloo",
        "State": "IA",
        "Zip": 50702,
        "LocalPhone": "(319) 234-0381",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(319) 234-0394",
        "LobbyHours": "M-Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m-12:30 p.m.",
        "DriveupHours": "M-Fr: 8 a.m.-6 p.m. Sat: 8 a.m.-1 p.m.  Drive-up ATM: 24 hours daily",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 21,
        "Latitude": 42.46173,
        "Longitude": -92.330702,
        "Active": 1
      },
      {
        "ID": 18,
        "BranchGroup": 1,
        "BranchName": "Waterloo, IA (Mullan Ave.)",
        "Address": "218 W Mullan Ave",
        "Address2": "",
        "City": "Waterloo",
        "State": "IA",
        "Zip": 50701,
        "LocalPhone": "(319) 235-0381",
        "TollFreePhone": "(800) 373-7600",
        "BranchExt": "",
        "Fax": "(319) 234-1084",
        "LobbyHours": "M-Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12:30 p.m.",
        "DriveupHours": "M-Fr: 8 a.m.-6:00 p.m. Sat: 8:00 a.m.-1:00 p.m.",
        "SharedBranching": 0,
        "BranchImage": "../images/branches/dupaco_default.jpg",
        "BranchID": 18,
        "Latitude": 42.498419,
        "Longitude": -92.346106,
        "Active": 1
      },
      {
          "ID": 70,
          "BranchGroup": 1,
          "BranchName": "Waterloo, IA (Live Video Teller Outlet @ MercyOne Waterloo Medical Center)",
          "Address": "3421 W 9th St",
          "Address2": "",
          "City": "Waterloo",
          "State": "IA",
          "Zip": 50702,
          "LocalPhone": "N/A",
          "TollFreePhone": "(800) 373-7600",
          "BranchExt": "",
          "Fax": "N/A",
          "LobbyHours": "M-Fr: 8:00 a.m.-6:00 p.m. Sat: 8:00 a.m.-1:00 p.m. Sun: 24 Hour ATM only",
          "DriveupHours": "No drive-up available",
          "SharedBranching": 0,
          "BranchImage": "../images/branches/dupaco_default.jpg",
          "BranchID": 22,
          "Latitude": 42.461057,
          "Longitude": -92.346031,
          "Active": 1
        },
        {
          "ID": 11,
          "BranchGroup": 1,
          "BranchName": "Cedar Falls, IA (Viking Rd.)",
          "Address": "126 Brandilynn Blvd.",
          "Address2": "",
          "City": "Cedar Falls",
          "State": "IA",
          "Zip": 50613,
          "LocalPhone": "(319) 277-3940",
          "TollFreePhone": "(800) 373-7600",
          "BranchExt": "",
          "Fax": "(319) 277-8912",
          "LobbyHours": "M-Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12:30 p.m. Sun: 24 Hour ATM only",
          "DriveupHours": "M-Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12:30 p.m.",
          "SharedBranching": 0,
          "BranchImage": "../images/branches/dupaco_default.jpg",
          "BranchID": 11,
          "Latitude": 42.485011,
          "Longitude": -92.444794,
          "Active": 1
        },
        {
          "ID": 30,
          "BranchGroup": 6,
          "BranchName": "Waukee, IA (Hickman Rd.)",
          "Address": "1090 E. Hickman Rd.",
          "Address2": "",
          "City": "Waukee",
          "State": "IA",
          "Zip": 50263,
          "LocalPhone": "(800) 373-7600",
          "TollFreePhone": "(800) 373-7600",
          "BranchExt": "",
          "Fax": "(319) 208-3633",
          "LobbyHours": "M-Fr: 8:30 a.m.-5:30 p.m. Sat: 8:30 a.m.-12:30 p.m.",
          "DriveupHours": "M-Fr: 8:00 a.m.-6:00 p.m. Sat: 8:00 a.m.-1:00 p.m.",
          "SharedBranching": 0,
          "BranchImage": "../images/branches/dupaco_default.jpg",
          "BranchID": 30,
          "Latitude": 41.614410,
          "Longitude": -93.853710,
          "Active": 1
        }
       
    ]`

  const [branchArray] = useState(JSON.parse(branchString));
  const [selectedBranch, setSelectedBranch] = useState(model.fundInBranch.branch);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedBranchModel, setSelectedBranchModel] = useState(branchArray.find((branch) => branch.ID.toString() === model.fundInBranch.branch));


  const handleNextClick = () => {
    setErrorMessage('');
    if (selectedBranch === undefined || selectedBranch.trim() === '') {
      setErrorMessage('Required');
      return null;
    }

    return '/complete';
  }

  return (
    <>
      <LeadText text="Let’s make your opening deposit(s)" subText="Please tell us which Dupaco branch is most convenient for you." ShowSaveForLater={false} />
      <FormFieldWrap isRequired={true} errorMessage={errorMessage}>
        <Row>
          <Col xs={12} className='mb-3'>
            <Input type="select" id='branch' value={selectedBranch} onChange={(e) => { setSelectedBranch(e.target.value); setSelectedBranchModel(branchArray.find((branch) => { return branch.ID.toString() === e.target.value })); onChangeHandler(e.target.id, e.target.value); }} >
              <option key={0} value=''>Please Select</option>
              {branchArray.map((branch, index) => {
                return (
                  <option key={branch.ID} value={branch.ID} >{branch.BranchName}</option>
                )
              })}
            </Input>
          </Col>
        </Row>
      </FormFieldWrap>
      <Row>
        {(selectedBranchModel !== undefined) &&
          <Col xs={12} >
            <address>
              <p><strong>Address:</strong><br />{selectedBranchModel.Address}&nbsp;
                {selectedBranchModel.Address2 && <> <span>{selectedBranchModel.Address2}</span>&nbsp;</>}
                <br />{selectedBranchModel.City}, {selectedBranchModel.State}, {selectedBranchModel.Zip} </p>
              <p><strong>Hours:</strong> <br />{selectedBranchModel.LobbyHours}</p>
              <p><strong>Phone number:</strong><br />{selectedBranchModel.TollFreePhone}</p>
            </address>
          </Col>
        }
        <Col xs={12} sm={12} className='mb-3'>
          <p>When making your opening deposit(s) in-branch, you'll be asked to provide your application number, <strong>{model.leadId}</strong>, and verbal identification password. </p>
          <p><i>Please note, we cannot officially open your account(s) until you make your opening deposit(s). We look forward to seeing you soon!</i></p>
        </Col>
      </Row>
      <NavigationButtons onNext={handleNextClick} setRouteTransition={setRouteTransition} ShowSaveForLater={false} showCancel={false} showBack={true} />
    </>
  );
}



export default InPerson;

